import {useRef, useState} from "react";
import {Card} from 'react-bootstrap';
import {Swiper, SwiperSlide} from "swiper/react/swiper-react";
import {Swiper as SwiperCore} from "swiper/types";
import {Navigation} from "swiper";
import {Category} from "../../../models/Category";
import {handleSlideChange} from "../../../helpers/swiper-helpers";

interface CategorySwiperProps {
    categories: Category[];
}

const CategorySwiper = (props: CategorySwiperProps) => {
    const swiperRef = useRef<SwiperCore>(null);
    const [previousDisabled, setPreviousDisabled] = useState(false);
    const [nextDisabled, setNextDisabled] = useState(false);

    return <div className={'container position-relative'}>
        <button className="btn swiper-control swiper-control-previous"
                onClick={() => swiperRef?.current?.slidePrev()}
                disabled={previousDisabled}>
                <span className="material-symbols-outlined fs-2">
                    arrow_back_ios
                </span>
        </button>

        <Swiper className={'category-swiper'}
                slidesPerView={'auto'}
                spaceBetween={20}
                onSlideChange={swiper => { handleSlideChange(swiper, setPreviousDisabled, setNextDisabled) }}
                onBeforeInit={swiper => {
                    // @ts-ignore
                    swiperRef.current = swiper;
                    handleSlideChange(swiper, setPreviousDisabled, setNextDisabled);
                }}
                modules={[Navigation]}>
            {props.categories?.map((category, index) => {
                return <SwiperSlide className={'category-slide'} key={index}>
                    <Card className="category mb-4 d-block text-decoration-none" as={'a'} href={`/categorie.html/${category.id}`}>
                        <Card.Img src={`${category.image}`} className={'h-100'} />
                        <Card.ImgOverlay className={'d-flex align-items-center justify-content-center'}>
                            <Card.Title className={'fw-bolder'}>
                                {category.categorie}
                            </Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                </SwiperSlide>
            })}
        </Swiper>

        <button className="btn swiper-control swiper-control-next"
                onClick={() => swiperRef?.current?.slideNext()}
                disabled={nextDisabled}>
                <span className="material-symbols-outlined fs-2">
                    arrow_forward_ios
                </span>
        </button>
    </div>
}

export default CategorySwiper;