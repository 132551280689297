import {createRoot} from 'react-dom/client';
import CategorySwiper from "./shared/category-swiper";
import RegionSwiper from "./shared/region-swiper";
import ExperienceSwiper from "./shared/experience-swiper";
import {Experience} from "../../models/Experience";
import {PagedData} from "../../models/paged-data";

declare const experiences: PagedData<Experience>;
declare const categories: any[];
declare const regions: any[];

const experienceWrapper = document.getElementById('experience-swiper');
if (experienceWrapper) {
    createRoot(experienceWrapper).render(<ExperienceSwiper experiences={experiences.data} />);
}

const categoryWrapper = document.getElementById('category-swiper');
if (categoryWrapper) {
    createRoot(categoryWrapper).render(<CategorySwiper categories={categories} />);
}

const regionWrapper = document.getElementById('region-swiper');
if (regionWrapper) {
    createRoot(regionWrapper).render(<RegionSwiper regions={regions} />);
}