import {Card} from "react-bootstrap";
import {Navigation} from 'swiper';
import {Swiper, SwiperSlide} from "swiper/react";
import {Town} from "../../../models/Town";
import {useRef, useState, MouseEvent} from "react";
import {Swiper as SwiperCore} from "swiper/types";
import {handleSlideChange} from "../../../helpers/swiper-helpers";

interface RegionSwiperProps {
    regions: Town[];
}

const RegionSwiper = (props: RegionSwiperProps) => {
    const swiperRef = useRef<SwiperCore>(null);
    const [nextDisabled, setNextDisabled] = useState(false);
    const [previousDisabled, setPreviousDisabled] = useState(false);

    return <div className={'container position-relative'}>
        <button className="btn swiper-control swiper-control-previous"
                onClick={(e: MouseEvent) => {
                    e.preventDefault();
                    e.stopPropagation();
                    swiperRef?.current?.slidePrev();
                }}
                disabled={previousDisabled}>
                <span className="material-symbols-outlined fs-2">
                    arrow_back_ios
                </span>
        </button>

        <Swiper className={'region-swiper'}
                slidesPerView={'auto'}
                onSlideChange={swiper => handleSlideChange(swiper, setPreviousDisabled, setNextDisabled)}
                onBeforeInit={swiper => {
                    // @ts-ignore
                    swiperRef.current = swiper;
                    handleSlideChange(swiper, setPreviousDisabled, setNextDisabled);
                }}
                modules={[Navigation]}>
            {props.regions?.map((region, index) => {
                return <SwiperSlide className={'region-slide zoomable-slide'} key={index}>
                    <Card>
                        <a className={'no-decoration-link'} href={`/search.html/${region.id}`}>
                            <Card.Img variant={'top'} src={`${region.image}`} />
                            <Card.Body>
                                <Card.Title>{region.ville}</Card.Title>
                            </Card.Body>
                        </a>
                    </Card>
                </SwiperSlide>
            })}
        </Swiper>

        <button className="btn swiper-control swiper-control-next"
                onClick={(e: MouseEvent) => {
                    e.preventDefault();
                    e.stopPropagation();
                    swiperRef?.current?.slideNext();
                }}
                disabled={nextDisabled}>
                <span className="material-symbols-outlined fs-2">
                    arrow_forward_ios
                </span>
        </button>
    </div>
}

export default RegionSwiper;