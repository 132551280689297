import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from 'swiper';
import {Experience} from "../../../models/Experience";
import ExperienceItem from "./experience/experience-item";
import {useRef, useState} from "react";
import {Swiper as SwiperCore} from "swiper/types";
import {handleSlideChange} from "../../../helpers/swiper-helpers";

export interface ExperienceSwiperProps {
    experiences: Experience[];
}

const ExperienceSwiper = ({experiences}: ExperienceSwiperProps) => {
    const swiperRef = useRef<SwiperCore>(null);
    const [previousDisabled, setPreviousDisabled] = useState(false);
    const [nextDisabled, setNextDisabled] = useState(false);

    return <div className={'container position-relative'}>
        <button className="btn swiper-control swiper-control-previous"
                onClick={() => swiperRef?.current?.slidePrev()}
                disabled={previousDisabled}>
                <span className="material-symbols-outlined fs-2">
                    arrow_back_ios
                </span>
        </button>

        <Swiper className={'experience-swiper'}
                slidesPerView={'auto'}
                navigation pagination
                onSlideChange={swiper => { handleSlideChange(swiper, setPreviousDisabled, setNextDisabled) }}
                onBeforeInit={swiper => {
                    // @ts-ignore
                    swiperRef.current = swiper;
                    handleSlideChange(swiper, setPreviousDisabled, setNextDisabled);
                }}
                modules={[Navigation, Pagination]}>
            {experiences?.map((experience, index) => {
                return <SwiperSlide className={'experience-slide zoomable-slide'}
                                    key={index}>
                    <ExperienceItem experience={experience} />
                </SwiperSlide>
            })}
        </Swiper>

        <button className="btn swiper-control swiper-control-next"
                onClick={() => swiperRef?.current?.slideNext()}
                disabled={nextDisabled}>
                <span className="material-symbols-outlined fs-2">
                    arrow_forward_ios
                </span>
        </button>
    </div>
};

export default ExperienceSwiper;