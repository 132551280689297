import { useState, useEffect } from 'react';
import { set, isBefore, add, intervalToDuration, Duration } from 'date-fns';
import ClampLines from 'react-clamp-lines';
import Card from 'react-bootstrap/Card';
import Placeholder from 'react-bootstrap/Placeholder';
import { Experience } from "../../../../models/Experience";

interface ExperienceItemProps {
    experience: Experience;
    placeholder?: boolean;
}

export default function ({ experience, placeholder }: ExperienceItemProps) {
    const [duration, setDuration] = useState<Duration | null>(null);

    function displayDuration(duration: Duration) {
        let value = '';
        if (duration.hours) {
            value += `${duration.hours}h `
        }
        if (duration.minutes) {
            value += `${duration.minutes.toString().padStart(2, '0')}m`
        }
        return value;
    }

    useEffect(() => {
        if (experience.heure_debut && experience.heure_fin) {
            const splitStart = experience.heure_debut.split(/:/g);
            const splitEnd = experience.heure_fin.split(/:/g);
            const startDate = set(new Date(), {hours: +splitStart[0], minutes: +splitStart[1]});
            let endDate = set(new Date(), {hours: +splitEnd[0], minutes: +splitEnd[1]});
            if (isBefore(endDate, startDate)) {
                endDate = add(endDate, {days: 1});
            }

            setDuration(intervalToDuration({start: startDate, end: endDate}));
        }
    }, [experience.heure_debut, experience.heure_fin]);

    return <Card className={'rounded bg-white border event-card h-100 experience-item'}
        as={'a'}
        href={placeholder ? undefined : `/experience.html/${experience.id}`}>
        {
            placeholder ?
                <></> :
                /*<Button className="btn bg-white border-0 event-favorite">
                    <span className="material-symbols-outlined text-primary">
                        favorite
                    </span>
                </Button>*/''
        }
        <Card.Img src={placeholder ? '/assets/img/default-image.jpg' : `/${experience.photo_couverture}`}
            variant="top"
            className="w-100" />
        <Card.Body className="p-3">
            {
                placeholder ?
                    <Placeholder animation={'glow'}
                        as={Card.Title}
                        xs={12}>
                        <p className="placeholder w-100"></p>
                    </Placeholder> :
                    <Card.Title className="mb-2">
                        <ClampLines text={experience.titre} lines={2} id={`event-title-${experience.id}`} buttons={false} />
                    </Card.Title>
            }
            <div className="event-text">
                {
                    placeholder ?
                        <>
                            <Placeholder xs={8} animation={'glow'} as={'div'}>
                                <div className="placeholder w-100"></div>
                            </Placeholder>
                            <Placeholder xs={4} animation={'glow'} as={'div'}>
                                <div className="placeholder w-100"></div>
                            </Placeholder>
                        </> :
                        <>
                            <div className="d-flex justify-content-between">
                                <div className="d-flex span-detail">
                                    <span className="material-symbols-outlined">
                                        pin_drop
                                    </span>&nbsp;{experience.ville}
                                </div>

                                <label className="event-price span-detail">{Math.floor(experience.prix)} &euro;</label>
                            </div>
                            <div className="d-flex justify-content-between span-detail">
                                <div className="d-flex">
                                    <span className="material-symbols-outlined">
                                        schedule
                                    </span>
                                    &nbsp;{duration ? displayDuration(duration) : ''}
                                </div>
                            </div>
                        </>
                }
            </div>
        </Card.Body>
    </Card>
}