import {Swiper as SwiperCore} from "swiper/types";

export const handleSlideChange = (swiper: SwiperCore, setPreviousDisabled, setNextDisabled) => {
    if (swiper.isBeginning) {
        setPreviousDisabled(true);
    } else if (swiper.isEnd) {
        setNextDisabled(true);
    } else {
        setPreviousDisabled(false);
        setNextDisabled(false);
    }
}